<template>
    <select-field :value="value"
                  :options="options"
                  label-key="translated_name"
                  class="select-document-type"
                  :loading="allowedDocumentTypesLoading"
                  as-key
                  independent
                  @change="handleChangeDocumentType" />
</template>

<script>
import { types } from '@/models/documents';
import { resourceable } from '@/store/connectors';
import SelectField from '@/components/fields/SelectField';

export default {
  components: {
    SelectField
  },
  mixins: [
    resourceable({ on: 'documents', name: 'allowedDocumentTypes' })
  ],
  props: {
    defaultValue: { type: String, default: types.WORK_DOCUMENTATION }
  },
  data() {
    return {
      value: this.defaultValue || '',
      options: [{ value: '', translated_name: 'Все' }]
    }
  },
  mounted() {
    this.fetchAllowedDocumentTypes().then((data) => {
      this.options = [ ...this.options, ...data ]
    })
  },
  methods: {
    handleChangeDocumentType(type) {
      this.value = type
      this.$emit('change-type', type)
    }
  }
}
</script>