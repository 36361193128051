<template>
    <page-header :returnable="false"
                 no-shadow
                 class="page-header">
        <template v-slot:title >
            <div class="page-header__title">
                <icon name="folder" 
                      :class="['page-header__title__icon', folderType]" />
                <p class="page-header__title__text">{{ title }}</p>
            </div>
        </template>
        <el-select ref="searchSelect"
                   v-model="searchQuery"
                   filterable
                   remote
                   :remote-method="searchDocuments"
                   autocomplete="on"
                   clearable
                   size="mini"
                   :loading="selectLoading"
                   loading-text="Ищем..."
                   :no-data-text="searchQuery.length < 3 ? 'Введите больше символов' : 'Нет данных'"
                   placeholder="Введите строку поиска..."
                   prefix-icon="el-icon-search"
                   style="width: 240px;"
                   class="mr-2 search"
                   @focus="searchOptionsFocus"
                   @keyup.enter.native.stop="openFirstDocument"
                   @clear="clearSearchQuery">
            <div slot="prefix">
                <i class="el-icon-search" />
            </div>
            <template v-if="isShowSearchOptions">
                <el-option v-for="(item, index) in options"
                           :key="index"
                           ref="searchOptions"
                           :label="item.chapter"
                           :value="searchQuery"
                           size="mini"
                           class="search__result-item">
                    <div v-loading="item.loading"
                         class="f-v-center"
                         @click.stop="handleShowInfo(item)">
                        <div style="flex: 1;">
                            <div style="max-width: 50%; overflow: inherit;">
                                {{ item.translated_type }}
                            </div>
                            <div v-if="item.chapter"
                                 class="find-text">
                                {{ item.chapter.substr(0, 550) }}
                            </div>
                            <div v-if="item.find_context"
                                 class="find-text">
                                Найдено в тексте: <span v-html="item.find_context.substr(0, 100)" />
                            </div>
                        </div>
                        <div>
                            <el-button v-if="isPdfFile(item)"
                                       type="text"
                                       icon="el-icon-view"
                                       class="ml-0 p-1"
                                       circle
                                       @click.stop="viewDocument(item)" />
                            <el-button type="text"
                                       class="ml-0 p-1"
                                       icon="el-icon-download"
                                       circle
                                       @click.stop="downloadDocument(item)" />
                        </div>
                    </div>
                </el-option>
            </template>
        </el-select>

        <!-- Add document -->
        <el-button
            v-if="currentFolder && currentFolder.type !== 'bim_360_docs'"
            type="primary"
            size="mini"
            @click="handleCreate">
            <el-icon class="el-icon-plus mr-1" />
            {{ addButtonName }}
        </el-button>


        <el-button-group class="ml-2">
            <el-button :type="view ? 'primary' : ''"
                       icon="el-icon-menu"
                       size="mini"
                       @click="switchView" />
            <el-button :type="!view ? 'primary' : ''"
                       icon="el-icon-s-fold"
                       size="mini"
                       @click="switchView" />
        </el-button-group>
    </page-header>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import downloadFile from 'js-file-download'
import axios from 'axios'

import { translateType, treeTypes } from '@/models/documents'

import PageHeader from '@/components/layout/PageHeader'
import { getFolderParents } from '@/utils/folders'

export default {
  name: 'DocumentHeader',
  components: {
    PageHeader
  },
  props: {
    view: { type: Boolean, required: true },
    type: { type: String, default: null },
    treeType: { type: String, default: treeTypes.TREE_BY_APPROVAL, require: false }
  },
  data() {
    return {
      searchQuery: '',
      isShowSearchOptions: true,
      searchMode: false,
      selectLoading: false,
      cancelTokenSource: false,
      options: [],
      requestProjectDocuments: {}
    }
  },

  computed: {
    ...mapGetters('documents', ['getFolderTree', 'currentFolder']),

    title() {
      let r

      r ||= this.searchMode && 'Результаты поиска'
      r ||= this.currentFolder?.name
      r ||= this.type && translateType(this.type)
      r ||=  'Документы'

      return r
    },

    folderType() {
      return this.currentFolder && this.currentFolder.type || 'local'
    },

    addButtonName() {
      return this.treeType === treeTypes.TREE ? 'Добавить документ' : 'Согласовать документ'
    }
  },
  methods: {
    ...mapActions('project', ['filterProjectDocuments', 'getProjectDocumentIndex', 'downloadProjectDocument', 'createProjectDocument', 'moveToFolder']),
    ...mapMutations({
      showForm: 'form/SHOW_FORM'
    }),
    ...mapActions('documents', ['setCurrentFolder']),


    // NOT REVIEWED

    changeLoading (item, type) {
      this.isShowSearchOptions = false
      item.loading = type

      this.$nextTick(() => {
        this.isShowSearchOptions = true
      })
    },
    isPdfFile(file) {
      let split = file.original_file_name?.split('.') || []
      return split[split.length - 1] === 'pdf'
    },
    async viewDocument (projectDocument) {
      this.changeLoading(projectDocument, true)

      let data = await this.downloadProjectDocument({
        projectId: this.$route.params.projectId,
        projectDocumentId: projectDocument.id
      })

      const file = new Blob(
        [data],
        {type: 'application/pdf'})
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
      this.changeLoading(projectDocument, false)

    },
    async downloadDocument(projectDocument) {
      this.changeLoading(projectDocument, true)

      try {
        let data = await this.downloadProjectDocument({
          projectId: this.$route.params.projectId,
          projectDocumentId: projectDocument.id
        })

        downloadFile(data, projectDocument.original_file_name)
      } catch (e) {
        console.log(e)
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.changeLoading(projectDocument, false)
      }
    },
    async searchDocuments (query) {
      if(query.length < 3) return this.selectLoading = false
      this.searchQuery = query

      try {
        // Если есть предыдущий запрос поиска, отменяем его
        if (this.selectLoading) this.cancelTokenSource.cancel()

        this.selectLoading = true
        this.cancelTokenSource = axios.CancelToken.source()

        let request = await this.$api.projectsV2.getProjectDocumentIndex(this.$route.params.projectId, {
          params: {
            find: query ? query : null
          },
          cancelToken: this.cancelTokenSource.token
        })

        this.options = request.data.data
        this.requestProjectDocuments = request
        this.selectLoading = false

      } catch (e) {
        // console.log(e)
      }
    },
    switchView() {
      this.$emit('switchView')
    },
    searchOptionsFocus () {
      setTimeout(() => {
        this.$refs.searchSelect.selectedLabel = this.searchQuery
      }, 200)
    },
    openFirstDocument () {
      this.searchMode = true
      this.filterProjectDocuments(this.requestProjectDocuments)

      // if (this.isPdfFile(this.options[0])) this.viewDocument(this.options[0]); else this.downloadDocument(this.options[0])
    },
    clearSearchQuery () {
      this.setCurrentFolder('local')
      this.searchMode = false
      this.$router.push({
        name: 'project.documents',
        query: {
          ...this.$route.query,
          page: null,
          folder: null
        }
      })
    },
    handleShowInfo(document) {
      this.showForm({
        formName: 'document-information-drawer',
        formTitle: 'Информация о документе',
        payload: document
      })
    },
    handleCreate() {
      const index = this.currentFolder.id.indexOf('-')
      const folderIds = this.treeType === treeTypes.TREE_BY_APPROVAL
        ? index > -1
          ? getFolderParents(this.getFolderTree.local, this.currentFolder.id.slice(index + 1))
          : null
        : getFolderParents(this.getFolderTree.local, this.currentFolder.id)

      if (this.treeType === treeTypes.TREE_BY_APPROVAL) {
        this.showForm({
          formName: 'task-bar-form',
          formTitle: 'Документ на согласование',
          action: this.createProjectDocument,
          callback: () => this.$emit('change'),
          payload: {
            type: this.type,
            folder_id: folderIds
          }
        })
      } else {
        this.showForm({
          formName: 'document-form',
          formTitle: 'Добавление документа',
          action: this.createProjectDocument,
          callback: () => this.$emit('change'),
          payload: {
            type: this.type,
            folder_id: folderIds
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
  .documents__content {
    .page-header {
      .el-input__prefix {
        display: flex;
        align-items: center;
      }
      .el-input__inner, .el-button {
        height: 30px;
      }
    }
  }
</style>
<style lang="scss" scoped>
.navigation {
  margin: 34px 0;
}
.page-header {
  white-space: nowrap;
  padding: 10px 16px;
  border-bottom: none;

  &__title {
    display: flex;
    align-items: center;

    &__icon {
      width: 16px;
      margin-right: 8px;
    }

    &__text {
      font-size: 13px;
      margin: 0;
      color: #303133;
    }
  }
}

.find-text {
  font-weight: 500;
  color: #606266;
  font-size: 13px;
  overflow: inherit;

  ::v-deep em {
    font-weight: 600;
  }
}

.search {
  .el-input__prefix {
    div {
      line-height: 28px;
      width: 24px;
    }
  }
  &__result-item {
    padding: 10px 20px;
    height: auto;

    max-width: 500px;

    div {
      line-height: 1.4;
      white-space: normal;
    }
  }
}
</style>
