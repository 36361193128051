/**
 * @param {import("axios").AxiosInstance} axios
 */
export default axios => ({
  getProjectDocuments: ({ project, params }) => axios.get(`projects/${project.id}/document/index`, { params }),

  getDocumentsCountByType: ({ project }) => axios.get(`projects/${project.id}/documents/count-by-type`),

  getAllowedDocumentTypes: ({ project }) => axios.get(`projects/${project.id}/documents/allowed-create-types`),

  getDocumentLogs: ({ project, document }) =>
    axios.get(`projects/${project.id || project.project_id}/document/history`, {
      filters: {
        'project_document': document.id
      }
    }).then(response => response?.data?.data || []),

  renderDocumentPage: ({ project, document, page }) =>
    axios.get(`projects/${project.id || project.project_id}/document/${document.id}/pages/${page}`)
      .then(response => response?.data?.data),

  compare: ({ project, documents, pages }) =>
    axios.post(`projects/${project.id || project.project_id}/document/compare`, {
      'first_document_id': documents[0].id,
      'second_document_id': documents[1].id,
      'first_document_page': pages[0],
      'second_document_page': pages[1]
    })
      .then(x => x?.data?.data),

  prepareDownload: ({ project, document }) =>
    axios.getUri({
      url: `projects/${project.id || project.project_id}/document/${document.id}/download`
    }),

  reprocess: ({ project, document }) =>
    axios.post(`projects/${project.id}/document/${document.id}/bim/send-for-reprocessing`),

  // NOT REVIEWED

  getFolderTree: (project, payload) => axios.get(`projects/${project}/document/folder/tree`, payload),
  getFolderTreeByApproval: (project, payload) => axios.get(`projects/${project}/document/folder/tree-by-approval`, payload),
  createNewFolder: (payload) => axios.post(`projects/${payload.project}/document/folder/create`, payload),
  renameFolder: (payload) => axios.put(`projects/${payload.project}/document/folder/${payload.folder}/update`, payload),
  rmFolder: (payload) => axios.delete(`projects/${payload.project}/document/folder/${payload.folder}/delete`, payload),
  moveFolder: (payload) => axios.post(`projects/${payload.project}/document/folder/move`, payload),
  moveDocument: (payload) => axios.post(`projects/${payload.project}/document/move`, payload),
  getTableOfContents: (payload) => axios.get(`projects/${payload.project}/document/${payload.documentId}/table-of-contents`, payload),
  getStructureTableOfContents: (payload) => 
    axios.get(`projects/${payload.projectId}/document/table-of-contents/${payload.structureType}/${payload.structureId}`, payload)
})
